<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
  <label>Insira mais informações</label>
    <VueEditor
      id="more-informations-text-area"
      class="vue_edition"
      v-model="form.fields.maisInformacoes"
      :editorToolbar="customToolbar"
      @input="value => debounceChangeValue('maisInformacoes', value)"
      :disabled="!this.validCanEdit()"
    />
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import { VueEditor } from "vue2-editor"
import { debounce } from 'lodash'


export default {
  components: { 
    FormWrapper,
     VueEditor,
     },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      canDefaultForms: state => state.attendance.canDefaultForms,
      form: state => state.attendance.form.maisInformacoes,
      selectedForm: state => state.attendance.form.selected,
    })
  },

  created() {
    this.debounceChangeValue = debounce(this.updateForm, 500)
  },

  data() {
    return {
      customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
    }
  },

  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord', 'defaultUpdateForm']),
    ...mapActions('attendance/form/maisInformacoes', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
      this.onBlur(this.form)
    },
    onBlur(value) {
      if(this.canDefaultForms) {
        this.defaultUpdateForm(value)
      }
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    },
    validCanEdit() {
      if(this.canDefaultForms === true || this.canEdit === true){
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    selectedForm() {
      const form = this.selectedForm.forms.find(f => f.id === 'mais-Informacoes');

      if (form && form.default_text && this.canEdit) {
        setTimeout(() => {
          this.updateForm('maisInformacoes', form.default_text)
					}, "3000")

      }
    }
  }
}
</script>
<style Lang="scss" scoped>
.vue_edition {
    background-color: white;
  }
  :deep(.ql-editor) {
      min-height: 100px !important;
    }
</style>

import { render, staticRenderFns } from "./MaisInformacoesForm.vue?vue&type=template&id=1f5e1174&scoped=true"
import script from "./MaisInformacoesForm.vue?vue&type=script&lang=js"
export * from "./MaisInformacoesForm.vue?vue&type=script&lang=js"
import style0 from "./MaisInformacoesForm.vue?vue&type=style&index=0&id=1f5e1174&prod&Lang=scss&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f5e1174",
  null
  
)

export default component.exports